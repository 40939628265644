import React from 'react';
import '../App.css';

class Requirements extends React.Component {
  render() {
    return <div className={"requirements"}>

      <h4>Learning requirements</h4>
      <ol>
        <li>
            Create sc
        </li>
      </ol>

      <h4>Website requirements</h4>
      <ol>
        <li className={"project-done"}>
          Create Basic create react project
        </li>
      </ol>
    </div>
  }
}

export default Requirements;
